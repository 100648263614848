import React, { useEffect, useState } from "react"
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { useAuth0, User } from "@auth0/auth0-react";
import inRole from "../types/userRoles";
import Dashboard from "../components/reporting/dashboard";
import MobileDashboard from "../components/reporting/mobileDashboard";

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "wastestockimage.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
`);

    //HOOKS
    const { user } = useAuth0();
    const theme = useTheme();
    const upMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    //COMPONENT STATE 
    const [hasReportingPermission, setHasReportingPermission] = useState(false);
  
    useEffect(() => {

      const checkUserRole = async () => {

        if(user)
        {
          const hasRole = inRole(user as User, "Administrator");
          setHasReportingPermission(hasRole);
        }

      }

      checkUserRole()

    }, [user])

    return (
        <Box>
          {
            hasReportingPermission && upMediumScreen &&
            <Dashboard />
          }
          {
            (!hasReportingPermission || !upMediumScreen) &&
            <MobileDashboard image={data.file.childImageSharp.gatsbyImageData} />
          }
        </Box>
    )
}

export default IndexPage
