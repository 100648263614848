import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Grid, Avatar, Container, Typography, Card, Box, Button, Badge, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FaCoins } from 'react-icons/fa';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import Layout from "../../layout";
import PageTitleWrapper from "../page/pageTitleWrapper";
import PageTitle from "../page/pageTitle";
import { resetState } from "../../store/quoteSlice";

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        box-shadow: ${theme.colors.shadows.success};
`);

const StyledLink = styled(Link) ({
  textDecoration: 'none'
})

export interface MobileDashboardProps {
    image: IGatsbyImageData
}

const MobileDashboard = (props:MobileDashboardProps) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const { user } = useAuth0();

    //HANDLERS
    const handleNewQuoteClick = () => {
        dispatch(resetState());
    }  

    return (
        <Layout>
            <PageTitleWrapper>
              <Grid container alignItems="center">
                <Grid item xs={3} sm={2} md={1}>
                    <Avatar
                      sx={{ mr: 2, width: theme.spacing(8), height: theme.spacing(8) }}
                      variant="rounded"
                      alt={user?.name}
                      src={user?.picture ?? ""}
                    />
                </Grid>
                <Grid item xs={9} sm={10} md={11}>
                  <PageTitle
                    heading={ "Welcome, " + user?.name } 
                    subHeading="Welcome to Digibin the home to your waste quoting and fulfillment requirements." />
                </Grid>
              </Grid>
            </PageTitleWrapper>
            <Container maxWidth="lg">
              <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}>
                <Grid item xs={12}>
                  <Card sx={{ mb: 2 }}>
                    <Grid spacing={0} container>
                      <Grid item xs={12} md={6}>
                        <Box p={4}>
                          <Typography sx={{ pb: 3 }} variant="h4">
                            Quotes Generated
                          </Typography>
                          <Box pb={4}>
                            <Box display="flex" alignItems="center">
                              <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
                                <FaCoins fontSize={30} />
                              </AvatarSuccess>
                              <Box>
                                <Typography variant="h1" gutterBottom>
                                  200
                                </Typography>
                                <Typography
                                  variant="h4"
                                  fontWeight="normal"
                                  color="text.secondary"
                                >
                                  this month
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Grid container spacing={3}>
                            <Grid sm item>
                              <StyledLink to="/viewleads">
                                <Button fullWidth variant="outlined" startIcon={<Badge><PersonOutlineTwoToneIcon /></Badge>}>
                                  My Leads
                                </Button>
                              </StyledLink>
                            </Grid>
                            <Grid sm item>
                              <StyledLink to="/quote">
                                <Button fullWidth variant="contained" startIcon={<Badge><AddCircleOutlineIcon /></Badge>} onClick={() => handleNewQuoteClick()}>
                                  New Quote
                                </Button>
                              </StyledLink>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <GatsbyImage image={props.image} alt="waste image" />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Container>
        </Layout>
    )
}

export default MobileDashboard